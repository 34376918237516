<template>
  <b-card no-body>
    <b-row
      align-h="between"
      align-v="center"
      no-gutters
      class="px-2 py-1 bg-primary"
      style="border-radius: 0.4rem;"
    >
      <b-col cols="auto" class="font-weight-bold text-uppercase text-white">
        {{ $t("assets") }}
      </b-col>
    </b-row>
    <b-overlay variant="white" spinner-variant="primary" rounded="sm">
      <hr class="m-0" />
      <transition-group name="list">
        <div v-for="(asset, index) in assets" :key="index">
          <AssetsCardTable :asset="{ ...asset, index }" />
        </div>
      </transition-group>
    </b-overlay>
  </b-card>
</template>

<script>
import AssetsCardTable from "@/components/assets/card/AssetsCardTable.vue";

export default {
  props: {
    assets: {
      tpye: Array,
    },
  },
  components: {
    AssetsCardTable,
  },
};
</script>
