<template>
  <section class="ficha" v-if="assetPrepare">
    <b-row >
      <b-col cols="12">
        <b-card no-body class="height-card position-relative px-2">
          <b-row class="my-2">
            <!-- Left: Product Image Container -->
            <b-col cols="12" lg="3" class="d-flex align-items-center justify-content-center mb-2 mb-md-0">
              <b-carousel id="carousel-fade" style="text-shadow: 0px 0px 2px #000" :indicators="images.length > 1"
                :interval="99999" img-width="1024" img-height="720">
                <b-carousel-slide v-for="(image, index) in images" :key="index" :img-src="image.img"></b-carousel-slide>
              </b-carousel>
            </b-col>
            <!-- Right: Product Details -->
            <b-col cols="12" lg="9" class="ficha-info">
              <!-- Product Name -->
              <div class="mb-1 mt-1">
                <b-badge v-if="installation.status && installation.status !== 'free'" :variant="statusInstallations[installation.status.alias]"
                  class="mr-50">
                  {{ $t(`status.installations.${installation.status.alias}`) }}
                </b-badge>
              </div>
              <div class="mb-1 mt-1">
                <h2 class="ficha-info_name mb-50">{{ installation.name }}</h2>
              </div>
              <!-- <div class="mb-2" v-if="installation.power">
                <b-badge variant="ligth" style="
                    border: 1px solid black;
                    color: black;
                    font-weight: 500;
                  ">
                  {{ $t("FacilityPower") }} {{ installation.power }} Wp (Watios pico)
                </b-badge>
              </div> -->
              <b-row class="mb-2" v-if="installation.status.alias == 'use' && installation.user">
                <b-col cols="12" xl="4" md="6" class="mb-2">
                  <div class="bg-light-fade p-1 h-100">
                    <div class="">
                      <b-row class="">
                        <b-link :to="{
    name: 'viewUser',
    params: { id: installation.user.id },
  }" class="mr-1">
                          <b-col cols="12" class="d-flex align-items-center">
                            <div class="pr-2">
                              <b-avatar size="4rem" :src="installation.user.avatar_url" />
                            </div>
                            <div>
                              <h4 class="m-0 font-weight-bolder">
                                {{ installation.user.name }} {{ installation.user.surname }}
                              </h4>

                              <span :key="r.display_name" v-for="r in installation.user.roles">
                                {{ r.display_name }}
                              </span>
                            </div>
                          </b-col>
                        </b-link>
                      </b-row>
                    </div>
                  </div>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12" xl="4" md="6" class="mb-2 d-lg-block d-none">
                  <div class="bg-light-fade p-1 h-100">
                    <p class="font-weight-bolder mb-0">
                      {{ $t("FechaConstruccion") }}
                    </p>
                    <p class="mb-0" v-if="installation.purchase_date">
                      {{ toDate(installation.purchase_date) }}
                    </p>
                    <p class="mb-0" v-else>-</p>
                  </div>
                </b-col>
                <b-col cols="12" xl="4" md="6" class="mb-2">
                  <div class="bg-light-fade p-1 h-100">
                    <p class="font-weight-bolder mb-0">
                      {{ $t("UltimaRevision") }}
                    </p>
                    <p class="mb-0" v-if="installation.last_review_date">
                      {{ toDate(installation.last_review_date) }}
                    </p>
                    <p class="mb-0" v-else>-</p>
                  </div>
                </b-col>
                <b-col cols="12" xl="4" md="6" class="mb-2">
                  <span v-if="installation.next_review_date && installation.next_review_date != 'null'
    ">
                    <div v-if="installation.next_review_date < nowTimestamp" class="bg-danger-fade p-1 h-100">
                      <p class="font-weight-bolder mb-0">
                        {{ $t("ProximaRevision") }}
                      </p>
                      <p class="mb-0">{{ toDate(installation.next_review_date) }}</p>
                      <b-link 
                        v-if="installation.task_next_review_date"
                        :to="{
                          name: 'viewTask',
                          params: { id: installation.task_next_review_date.id },
                        }" 
                        class="link-blue truncated-text mt-1"
                      >
                        {{ installation.task_next_review_date.name }}
                        <feather-icon size="18" icon="ArrowRightIcon" />
                      </b-link>
                    </div>
                    <div v-else class="bg-light-fade p-1 h-100">
                      <p class="font-weight-bolder mb-0">
                        {{ $t("ProximaRevision") }}
                      </p>
                      <p class="mb-0">{{ toDate(installation.next_review_date) }}</p>
                    </div>
                  </span>
                  <span v-else>
                    <div class="bg-light-fade p-1 h-100">
                      <p class="font-weight-bolder mb-0">
                        {{ $t("ProximaRevision") }}
                      </p>
                      <p class="mb-0">-</p>
                    </div>
                  </span>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
      <!-- CLIENT-INFO -->
      <b-col cols="12" v-if="installation.subclient">
        <b-card>
          <h4 class="font-weight-bolder">{{ $t("ClientInformation") }}</h4>
          <hr class="my-1" />
          <div class="pt-1">
            <span class="font-weight-bolder"> {{ $t("NombreCliente") }}: </span>
            <span>{{ installation.subclient.name }}</span>
          </div>
          <template v-if="clientContactPersons">
            <div class="py-1">
              <span class="font-weight-bolder">
                {{ $t("ContactPersons") }}:
              </span>
            </div>
            <b-row>
              <b-col v-for="contact in clientContactPersons" :key="contact.id" sm="12" md="4">
                <b-card style="background: #f7f5f0">
                  <div>
                    <span class="mr-1 font-weight-bolder">
                      {{ contact.name }}
                    </span>
                  </div>
                  <b-link v-if="contact.phone" :href="'tel:' + contact.phone" class="d-flex align-items-center"
                    style="color: #1c73e8; text-decoration: underline">
                    <feather-icon size="18" icon="PhoneIcon" class="mr-50" />
                    <span class="mr-1">
                      {{ contact.phone }}
                    </span>
                  </b-link>
                  <b-link v-if="contact.email" :href="'mailto:' + contact.email" class="d-flex align-items-center"
                    style="color: #1c73e8; text-decoration: underline">
                    <feather-icon size="18" icon="MailIcon" class="mr-50" />
                    <span class="mr-1">
                      {{ contact.email }}
                    </span>
                  </b-link>

                </b-card>
              </b-col>
            </b-row>
          </template>
        </b-card>
      </b-col>
      <!-- FACILITY INFO -->
      <b-col cols="12">
        <b-card>
          <h4 class="font-weight-bolder">{{ $t("DetallesInstallation") }}</h4>
          <hr class="my-1" />
          <b-card v-if="installation.tag.image_url || installation.location || installation.location_relation
    " no-body bg-variant="light" :class="{ 'p-2': !['xs', 'sm'].includes(breakpoint) }">
            <b-row>
              <!-- QR -->
              <b-col v-if="installation.tag.image_url" sm="12" md="4" class="ficha-tag d-lg-block d-none">
                <b-card no-body style="height: 100%">
                  <div class="p-2" v-if="installation.tag">
                    <template v-if="installation.tag.image_url">
                      <h5 class="mb-1 font-weight-bolder">{{ $t("QR") }}</h5>
                      <div class="text-center mb-2">
                        <img :src="installation.tag.image_url" class="img-fluid" />
                      </div>
                    </template>
                    <p class="mb-50" v-if="installation.tag.last_scan">
                      <span class="font-weight-bolder">{{ $t("UltimoRegistro") }}:</span>
                      {{ toDateWithTime(installation.tag.last_scan) }}
                    </p>
                    <p v-if="installation.tag.total_scans" class="mb-50">
                      <span class="font-weight-bolder">{{ $t("TotalRegistros") }}:</span>
                      {{ installation.tag.total_scans }}
                    </p>
                  </div>
                </b-card>
              </b-col>
              <!-- LOCATION -->
              <b-col v-if="latitude && longitude" sm="12" md="8">
                <b-card no-body style="height: 100%">
                  <div :class="{ 'p-1': !['xs', 'sm'].includes(breakpoint) }">
                    <h5 class="mb-1 font-weight-bolder">
                      {{ $t("Localizacion") }}
                    </h5>
                    <p class="mb-1" v-if="locActual">{{ installation.location }}</p>
                    <div>
                      <l-map :zoom="zoom" :center="center">
                        <l-tile-layer :url="url" />
                        <l-marker :lat-lng="[latitude, longitude]" />
                      </l-map>
                    </div>
                  </div>
                </b-card>
              </b-col>
            </b-row>
          </b-card>

          <b-card bg-variant="light" v-if="installation.info_docs.length > 0" >
            <div class="bg-light mb-2">
              <div class="font-weight-bolder mb-1">
                {{ $t("InterestingDocs") }}:
              </div>
              <div class="d-flex flex-wrap gap-1">
                <!-- <b-link
                  v-if="installation.production_access"
                  class="btn btn-outline-secondary"
                  :href="installation.production_access"
                  target="_blank"
                >
                  {{ $t('ProductionAccessLink') }}
                </b-link> -->
                <template v-if="installation.info_docs.length > 0">
                  <b-link
                    class="btn btn-outline-secondary word-break-all"
                    v-for="(doc, index) in files"
                    :key="index"
                    target="_blank"
                    :href="doc.path"
                  >
                    <feather-icon icon="FileIcon" />
                    {{ doc.name }}
                  </b-link>
                </template>
              </div>
            </div>
          </b-card>

          <b-card bg-variant="light">
            <div v-if="installation.observations" class="mb-2">
              <span class="font-weight-bolder">
                {{ $t("CoverObservations") }}:
              </span>
              <div v-html="installation.observations"></div>
            </div>

            <div class="d-flex flex-wrap mb-2">
              <div class="flex-grow-1">
                <div class="font-weight-bolder">{{ $t("CoverType") }}:</div>
                <span>{{
    installation.cover_type
      ? $t(`${capitalizeText(installation.cover_type)}`)
      : "--"
  }}</span>
                <span v-if="installation.cover_type === 'tilt'">
                  de: {{ installation.angle }}°
                </span>
              </div>
            </div>

            <div>
              <div class="font-weight-bolder mb-1">
                {{ $t("SecurityElements") }}:
              </div>
              <div class="d-flex flex-wrap gap-1 mb-1">
                <div :class="'d-inline-flex align-items-center rounded-pill ' +
    statusColorVariant(installation.linea_vida)
    ">
                  <b-img fluid :src="statusIconVariant(installation.linea_vida)" alt="LineaVida"
                    style="height: 20px; width: 20px" />
                  <span class="mb-0">
                    {{ $t("Línea de vida") }}
                  </span>
                </div>
                <div :class="'d-inline-flex align-items-center rounded-pill ' +
    statusColorVariant(installation.barandillas_seguridad)
    ">
                  <b-img fluid :src="statusIconVariant(installation.barandillas_seguridad)" alt="Barandilla"
                    style="height: 20px; width: 20px" />
                  <span class="mb-0">
                    {{ $t("Barandillas de seguridad") }}
                  </span>
                </div>
                <div :class="'d-inline-flex align-items-center rounded-pill ' +
    statusColorVariant(installation.redes_contencion)
    ">
                  <b-img fluid :src="statusIconVariant(installation.redes_contencion)" alt="RedesContencion"
                    style="height: 20px; width: 20px" />
                  <span class="mb-0">
                    {{ $t("Redes de contanción") }}
                  </span>
                </div>
                <div :class="'d-inline-flex align-items-center rounded-pill ' +
    statusColorVariant(installation.zona_delimitada)
    ">
                  <b-img fluid :src="statusIconVariant(installation.zona_delimitada)" alt="ZonaDelimitada"
                    style="height: 20px; width: 20px" />
                  <span class="mb-0">
                    {{ $t("Zona de trabajo delimitada") }}
                  </span>
                </div>
              </div>
              <div v-if="installation.security_elements">
                <div v-html="installation.security_elements"></div>
              </div>
            </div>
          </b-card>
        </b-card>
      </b-col>
      <b-col v-if="installation.assets_show.length > 0" cols="12">
        <AssetsCards :assets="installation.assets_show" />
      </b-col>
      <!-- FACILITIES -->
      <b-col cols="12" class="ficha-localizacion d-lg-block">
        <b-card no-body>
          <b-row>
            <b-col sm="12" md="9">
              <b-tabs
                style="font-weight: bolder"
                lazy pills
                nav-class="flex-column flex-md-row"
                :align="(['xs', 'sm'].includes(breakpoint)) ? 'center' : 'start'"
                @activate-tab="tab = $event"
              >
                <b-tab :active="tab === 0">
                  <template #title>
                    {{ $t("Actions") }}
                  </template>
                </b-tab>
                <b-tab :active="tab === 1">
                  <template #title>
                    {{ $t("NextActions") }}
                  </template>
                </b-tab>
                <b-tab :active="tab === 2">
                  <template #title>
                    {{ $t("ExternalActions") }}
                  </template>
                </b-tab>
              </b-tabs>
            </b-col>
            <b-col
              sm="12"
              md="3"
              class="d-flex justify-content-end align-self-center pr-2"
              :class="{ 'py-1': ['xs', 'sm'].includes(breakpoint) }"
              >
              <div v-if="tab === 2 && (currentRole === 'admin_empresa' || currentRole === 'admin_cliente' || currentRole === 'super_admin')">
                <b-button
                    @click="externalActionModal = true"
                    variant="outline-primary"
                    size="sm"
                >
                  <span> {{ $t("AddAction") }}</span>
                </b-button>
              </div>
            <div v-else class="d-flex" >
              <span v-if="['xs', 'sm'].includes(breakpoint)" style="margin-right: 5px">
                {{ $t("Filters") }}
              </span>
              <feather-icon
                class="mr-1 cursor-pointer d-flex align-self-center"
                icon="FilterIcon"
                size="20"
                @click="visibleFilter = !visibleFilter"
              />
            </div>
            </b-col>
          </b-row>
          <template v-if="tab === 0">
            <ActionsTable :visibleFilter="visibleFilter" type="installation" />
          </template>
          <template v-if="tab === 1">
            <NextActionsTable :visibleFilter="visibleFilter" type="installation" />
          </template>
          <template v-if="tab === 2">
            <ExternalActionsTable type="installation" />
          </template>
        </b-card>
      </b-col>
    </b-row>
    <CreateExternalActionModal v-model="externalActionModal" object_type="installation" />
  </section>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Ripple from "vue-ripple-directive";
import { isUserLoggedIn } from "@/auth/utils";
import { config } from "@/shared/app.config";
import { LMap, LMarker, LTileLayer } from "vue2-leaflet";
import { latLng, Icon } from "leaflet";
import "leaflet/dist/leaflet.css";
import {
  TimestampToFlatPickr,
  TimestampToFlatPickrWithTime,
} from "@/libs/helpers";
import { capitalizeText } from "@/shared/helpers";
import ActionsTable from "@/components/actions/table/ActionsTable.vue";
import NextActionsTable from "@/components/actions/table/NextActionsTable.vue";
import { BTabs, BTab } from "bootstrap-vue";
import CreateExternalActionModal from "@/components/actions/modal/CreateExternalActionModal.vue"
import ExternalActionsTable from "@/components/actions/table/ExternalActionsTable.vue";
import AssetsCards from "@/components/assets/card/AssetsCards.vue"

delete Icon.Default.prototype._getIconUrl;

Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});

export default {
  components: {
    BTabs,
    BTab,
    LMap,
    LTileLayer,
    LMarker,
    ActionsTable,
    NextActionsTable,
    CreateExternalActionModal,
    ExternalActionsTable,
    AssetsCards
  },
  directives: {
    Ripple,
  },
  mounted() {
    this.stacked = window.innerWidth <= 1400;
    window.addEventListener("resize", () => {
      this.stacked = window.innerWidth <= 1400;
    });
  },
  data() {
    return {
      tab: 0,
      capitalizeText,
      visibleInfo: false,
      statusInstallations: config.statusInstallationsVariants,
      stacked: false,
      visibleFilter: false,
      nowTimestamp: Math.floor(Date.now() / 1000),
      url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
      urlLocation: "",
      zoom: 13,
      zoomLocation: 5,
      latitude: 0,
      longitude: 0,
      locActual: true,
      textEventLoc: "",
      assetPrepare: false,
      defaultImage: require("@/assets/images/default/asset.png"),
      externalActionModal: false,
    };
  },
  computed: {
    ...mapGetters({
      installation: "installations/getInstallation",
      items: "events/getItemsLogs",
      currentRole: "auth/getRole",
      breakpoint: "app/currentBreakPoint",
    }),
    statusIconVariant() {
      const statusIcon = {
        null: require("@/assets/images/icons/cancel.svg"),
        1: require("@/assets/images/icons/check.svg"),
        0: require("@/assets/images/icons/cancel.svg"),
      };

      return (status) => statusIcon[status];
    },
    statusColorVariant() {
      const statusColor = {
        null: "rounded-pill-gray",
        1: "rounded-pill-green",
        0: "rounded-pill-gray",
      };

      return (status) => statusColor[status];
    },
    canDownload() {
      if (this.installation && this.installation.status) {
        if (
          this.installation.status.alias === "incidence" ||
          this.installation.status.alias === "use"
        ) {
          return true;
        }
      }
      return false;
    },
    fileDownload() {
      if (this.installation && this.installation.status) {
        if (
          this.installation.status.alias === "incidence" &&
          this.installation.archives.incidence
        ) {
          return this.installation.archives.incidence.url;
        } else if (
          this.installation.status.alias === "use" &&
          this.installation.archives.delivery
        ) {
          return this.installation.archives.delivery.url;
        }
      }
      return false;
    },
    textDownload() {
      if (this.installation && this.installation.status) {
        if (
          this.installation.status.alias === "incidence" &&
          this.installation.archives.incidence
        ) {
          return this.$t("InformeIncidencia");
        } else if (
          this.installation.status.alias === "use" &&
          this.installation.archives.delivery
        ) {
          return this.$t("CertificadoEntrega");
        }
      }
      return "";
    },
    center() {
      return latLng(this.latitude, this.longitude);
    },
    images() {
      const imgs = [];
      if (this.installation.imagen) {
        imgs.push({ img: this.installation.imagen });
      }
      if (this.installation.documents.length > 0) {
        this.installation.documents.forEach(({ path }) => {
          imgs.push({ img: path });
        });
      }

      if (imgs.length == 0) imgs.push({ img: this.defaultImage });

      return imgs;
    },
    files() {
      const files = this.installation.info_docs.length > 0 ? this.installation.info_docs : [];

      return files;
    },
    isLogin() {
      return isUserLoggedIn();
    },
    clientContactPersons() {
      return this.installation.users_contact;
    },
  },
  watch: {
    currentPage() {
      this.chargeData();
    },
    pageLength() {
      this.chargeData();
    },
    user() {
      this.currentPage = 1;
      this.chargeData();
    },
  },
  methods: {
    ...mapActions({
      getInstallation: "installations/getInstallation",
    }),
    toDate(f) {
      if (f) {
        return TimestampToFlatPickr(f);
      }
      return "";
    },
    toDateWithTime(f) {
      if (f) {
        return TimestampToFlatPickrWithTime(f);
      }
      return "";
    },
    openMap(latitude, longitude, text, hour) {
      this.latitude = latitude;
      this.longitude = longitude;
      this.locActual = false;
      this.textEventLoc = `${text} - ${hour}`;
    },
    setData() {
      if (this.installation.location_relation) {
        this.latitude = this.installation.location_relation.latitude;
        this.longitude = this.installation.location_relation.longitude;
      } else {
        this.latitude = "";
        this.longitude = "";
      }
      this.locActual = true;
      this.assetPrepare = true;
    },
    handleOpenMapLinkClick(installation) {
      this.openMap(
        installation.location?.latitude || installation.latitude,
        installation.location?.longitude || installation.longitude,
        this.$t(`typeevents.${installation.type}`),
        this.toDateWithTime(installation.created_at)
      );
    },
    priceFormatted(price) {
      let val = (price / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
  },
  async created() {
    await this.getInstallation(this.$route.params.id);
    await this.setData();
  },
};
</script>
<style lang="scss">
@import "~@core/scss/base/pages/app-ecommerce-details.scss";
@import "@core/scss/vue/libs/vue-select.scss";


.dropdown-user{
  list-style-type: none;
}
[dir] .b-nav-dropdown .dropdown-toggle::after{
  display: none;
}

.vue2leaflet-map {
  &.leaflet-container {
    height: 350px;
  }
}

.components-table table thead tr th {
  background: lightgray !important;
  color: black !important;
}

.filter-icon {
  position: absolute;
  right: 20px;
  top: 15px;
}

.nav-pills {
  margin-bottom: 0;
}

.carousel-indicators {
  position: relative;
}

.carousel-indicators li {
  margin-top: 5px;
  background: black;
  border-radius: 50%;
  width: 9px;
  height: 10px;
  border-top: none;
  border-bottom: none;
}


</style>
